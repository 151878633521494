import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../img/logo.png';


export default function Header() {
  return (
    <>
	<header id='header'>
		<div className='header-block h-100'>
			<div className='container h-100'>
				<div className='header-caption d-flex align-items-center justify-content-center h-100'>
					<div className='main-logo-box flex-grow-1 text-lg-center'>
						<Link className='logo-bar' to="/" >
							<img src={logo} alt="" />
						</Link>
					</div>
					<Link to="https://pangea.web4.world/" target='_blank' className='fill-btn bg-white text-black d-flex align-items-center gap-1'>
						<span>Pangea</span>
						<div className='icon-bar'>
							<svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 30" fill="none">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M8.67073 8.81286C7.02978 10.4538 6.10791 12.6794 6.10791 15C6.10791 17.3207 7.02978 19.5463 8.67073 21.1872C10.3117 22.8282 12.5373 23.75 14.8579 23.75C17.1786 23.75 19.4042 22.8282 21.0451 21.1872C22.686 19.5463 23.6079 17.3207 23.6079 15C23.6079 12.6794 22.686 10.4538 21.0451 8.81286C19.4042 7.17192 17.1786 6.25005 14.8579 6.25005C12.5373 6.25005 10.3117 7.17192 8.67073 8.81286ZM21.929 22.0711C20.0536 23.9465 17.5101 25 14.8579 25C12.2057 25 9.66221 23.9465 7.78684 22.0711C5.91148 20.1957 4.85791 17.6522 4.85791 15C4.85791 12.3479 5.91148 9.80434 7.78684 7.92898C9.66221 6.05361 12.2057 5.00005 14.8579 5.00005C17.5101 5.00005 20.0536 6.05361 21.929 7.92898C23.8043 9.80434 24.8579 12.3479 24.8579 15C24.8579 17.6522 23.8043 20.1957 21.929 22.0711ZM10.4836 15.5808C10.3177 15.5809 10.1586 15.5151 10.0413 15.3979C9.9239 15.2807 9.8579 15.1217 9.85778 14.9559C9.85766 14.79 9.92344 14.6309 10.0406 14.5135C10.1578 14.3962 10.3168 14.3302 10.4827 14.3301L17.7243 14.3309L15.2778 11.8844C15.1605 11.7671 15.0947 11.6082 15.0947 11.4424C15.0947 11.2767 15.1605 11.1177 15.2778 11.0005C15.395 10.8833 15.5539 10.8174 15.7197 10.8174C15.8855 10.8174 16.0444 10.8833 16.1616 11.0005L19.6751 14.5139C19.7923 14.6311 19.8581 14.7901 19.8581 14.9559C19.8581 15.1216 19.7923 15.2806 19.6751 15.3978L16.1616 18.9112C16.0444 19.0284 15.8855 19.0943 15.7197 19.0943C15.5539 19.0943 15.395 19.0284 15.2778 18.9112C15.1605 18.794 15.0947 18.635 15.0947 18.4693C15.0947 18.3035 15.1605 18.1446 15.2778 18.0273L17.7243 15.5808L10.4836 15.5808Z" fill="black"/>
							</svg>
						</div>
					</Link>
				</div>
			</div>
		</div>
	</header>
    </>
  );
};
